<template>
	<b-modal
		id="interchange-modal"
		class="default-modal"
		size="md"
		hide-footer
		centered
		data-backdrop="static"
		:title="$t('InterchangeModal.Interchange')"
		@show="interchangeShow"
	>
		<b-alert show fade v-if="showNotice" variant="light">
			<div class="text-danger">{{ $t("InterchangeModal.WinoverExist") }}</div>
			<div>{{ $t("InterchangeModal.NotTransferred") }}</div>
		</b-alert>
		<b-container class="px-4 position-relative" v-if="!showGotExternalWinover">
			<b-row>
				<b-col cols="6" class="siteItem" v-for="siteItem in interchangeSite" :key="siteItem.id">
					<div
						class="d-flex align-items-center justify-content-center w-100 h-100 siteBtn"
						:class="{ disabledImg: isDisabled }"
					>
						<img
							:src="siteItem.img"
							class="w-100"
							@click="getAspUserClient({ clientId: siteItem.clientId, url: siteItem.redirectUrl })"
						/>
					</div>
				</b-col>
			</b-row>
		</b-container>
		<b-container class="px-5 mb-4 position-relative" v-else>
			<b-row class="justify-content-center">
				<b-col cols="12"
					>{{ this.$t("InterchangeModal.ExternalWinoverExistMessage") }}
					<br />
					{{ $t("InterchangeModal.DoYouWishToProceed") }}</b-col
				>
				<b-col cols="12" class="mt-3">
					<div class="d-flex justify-content-around">
						<b-button variant="confirmWarning" @click="redirectWebsite">{{
							this.$t("InterchangeModal.Proceed")
						}}</b-button>
					</div>
				</b-col>
			</b-row>
		</b-container>
		<div class="d-flex justify-content-center align-items-center my-3" v-if="getVendorRegisterStatus">
			<font-awesome-icon :icon="['fas', 'spinner']" class="fa-spin-pulse fa-2x me-2"> </font-awesome-icon>
			<div class="text-navy h6 mb-0">{{ this.$t("InterchangeModal.WaitingCreateAccountMessage") }}</div>
		</div>
		<div class="d-flex justify-content-center align-items-center my-3" v-if="showWaitingTransferMessage">
			<font-awesome-icon :icon="['fas', 'spinner']" class="fa-spin-pulse fa-2x me-2"> </font-awesome-icon>
			<div class="text-navy h6 mb-0">{{ this.$t("InterchangeModal.WaitingTransferMessage") }}</div>
		</div>
		<div class="d-flex justify-content-center align-items-center my-3 flex-column" v-if="showTransferErrorMessage">
			<div class="text-navy h6 mb-0">
				{{ this.$t("InterchangeModal.TransferFailed") }} <br />{{ $t("InterchangeModal.DoYouWishToProceed") }}
			</div>
			<br />
			<b-button squared variant="confirmWarning" @click="redirectWebsite" class="headerLoginBtn">
				{{ $t("InterchangeModal.Proceed") }}
			</b-button>
		</div>
	</b-modal>
</template>

<style scoped>
.btn-confirmWarning {
	height: 39px;
	width: 100%;
	background: #6a91d2 !important;
	border-radius: 19.5px !important;
}
.siteBtn {
	cursor: pointer;
}
.siteBtn.disabledImg {
	opacity: 0.5;
	cursor: not-allowed;
}
.siteItem {
	padding-bottom: 2rem;
}
</style>
<script>
import { RootMutations } from "@/store/mutations";
import CryptoJS from "crypto-js";
export default {
	data() {
		return {
			interchangeSite: [],
			showNotice: false,
			showWaitingCreateAccountMessage: false,
			showWaitingTransferMessage: false,
			showTransferErrorMessage: false,
			showGotExternalWinover: false,
			isDisabled: false,
			redirectUrl: "",
			filterIP: ["2001:f40:908:9a8", "2001:e68:5433:ad89", "2001:f40:908:930"],
		};
	},
	computed: {
		getVendorRegisterStatus() {
			return this.$store.getters["getVendorRegisterStatus"];
		},
		isLogin() {
			return this.$store.getters["identityServer/getLoginStatus"];
		},
		getCurrentLang() {
			return this.$root.$i18n.locale;
		},
		getIPAddress() {
			return this.$store.getters["ipAddress/getIPAddress"];
		},
	},
	components: {},
	methods: {
		async checkWinover() {
			await this.$store.dispatch("memberAccount/fetchMemberWinover", { loginId: this.getLoginId, isFromDB: true });
			await this.$store.dispatch("memberAccount/fetchVendorBalance", { isFromDB: false }).then(() => {
				const latestBalance = this.$store.getters["memberAccount/getVendorBalance"].toFixed(2);
				const memberWinover = this.$store.getters["memberAccount/getMemberWinover"];
				if (memberWinover.winoverBalances.length > 0) {
					const latestWinover = memberWinover.winoverBalances[0].winover;
					const group = memberWinover.winoverBalances[0].category;
					const lockdown = memberWinover.winoverBalances[0].lockdown;

					if (latestWinover > 0) {
						this.showNotice = true;
						this.$store.dispatch("vendors/fetchCategoryList").then(() => {
							const vendorCategory = this.$store.getters["vendors/getCategory"];
							this.showNotice = true;
							if (latestBalance <= vendorCategory.resetThreshold) {
								this.$store.dispatch("memberAccount/resetWinover", {
									group: group,
									winover: latestWinover,
									lockdown: lockdown,
								});
								this.$store
									.dispatch("memberAccount/fetchMemberWinover", { loginId: this.getLoginId, isFromDB: true })
									.then(() => {
										this.showNotice = false;
									});
							}
						});
					} else {
						this.showNotice = false;
					}
				} else {
					this.showNotice = false;
				}
			});
		},
		async getAspUserClient(details) {
			let transferStatus = true;
			this.redirectUrl = details.url;
			if (this.isLogin) {
				if (!this.isDisabled) {
					this.isDisabled = true;
					await this.$store.dispatch("member/fetchAspUserClient", details).then(() => {
						this.$store.commit(RootMutations.SET_VENDOR_REGISTER_STATUS, false);
						if (!this.showNotice) {
							this.showWaitingCreateAccountMessage = true;
						}
					});

					const memberBalance = this.$store.getters["memberAccount/getVendorBalance"].toFixed(2);

					if (memberBalance > 0) {
						const isWinoverExist = await this.fetchExternalVendorWinover(process.env.VUE_APP_IdentityServer_ClientId).then((winoverExist) => {
							return winoverExist;
						});
						if (!isWinoverExist) {
							const currentVendorName = this.$store.getters["vendors/getVendor"].name;
							const vendorToName = await this.$store
								.dispatch("vendors/fetchExternalVendor", details.clientId)
								.then((externalVendor) => {
									return externalVendor[0].name;
								});
							const transferVendor = { vendorFrom: currentVendorName, vendorTo: vendorToName };
							this.showWaitingCreateAccountMessage = false;
							this.showWaitingTransferMessage = true;
							await this.$store.dispatch("payment/transferBalance", transferVendor).then((transferResponse) => {
								if (!transferResponse.succeeded) {
									transferStatus = false;
									this.showWaitingTransferMessage = false;
									this.showTransferErrorMessage = true;
									this.$store.dispatch("memberAccount/fetchVendorBalance", { isFromDB: false });
									return;
								}
							});
						} else {
							transferStatus = false;
						}
					}
					this.isDisabled = false;

					if (transferStatus) {
						this.redirectWebsite(details);
					}
				}
			} else {
				window.location.replace(this.redirectUrl);
			}
		},
		redirectWebsite() {
			const token = localStorage.getItem("token");
			const currentCulture = this.getCurrentLang;
			let tokenString = "";
			if (token) {
				const cipher = CryptoJS.AES.decrypt(token, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_Encryption_Key), {
					iv: CryptoJS.enc.Utf8.parse(process.env.VUE_APP_Initial_Vector),
					mode: CryptoJS.mode.CBC,
				});
				tokenString = CryptoJS.enc.Utf8.stringify(cipher).toString();
			}
			window.location.replace(this.redirectUrl + "interchange/" + tokenString + "/" + currentCulture);
		},
		// async fetchExternalVendor(clientId) {
		// 	// transfer pass externalVendor.id
		// },
		async fetchExternalVendorWinover(clientId) {
			const externalVendorWinover = await this.$store.dispatch("memberAccount/fetchExternalVendorWinover", clientId);
			if (externalVendorWinover.winoverBalances.length > 0) {
				if (externalVendorWinover.winoverBalances.some(x => x.winover > 0)) {
    				this.showGotExternalWinover = true;
    				return true;
				}
			}
			return false;
		},
		async getTenant() {
			this.$store.dispatch("tenant/fetchClientTenantInfo").then(() => {
				const tenantList = this.$store.getters["tenant/getClientTenantInfo"];
				const clientId = `${process.env.VUE_APP_IdentityServer_ClientId}`;
				let tenantInfo = tenantList.filter((x) => x.clientId != clientId);

				let isMatchIP = false;
				this.filterIP.forEach((filterIPItem) => {
					if (this.getIPAddress.includes(filterIPItem)) {
						return (isMatchIP = true);
					}
				});
				if (!isMatchIP) {
					tenantInfo = tenantInfo.filter((x) => {
						return x.isActive;
					});
				}
				this.interchangeSite = tenantInfo;
			});
		},
		resetDefault() {
			this.showNotice = false;
			this.showWaitingCreateAccountMessage = false;
			this.showWaitingTransferMessage = false;
			this.showTransferErrorMessage = false;
			this.showGotExternalWinover = false;
			this.isDisabled = false;
		},
		interchangeShow() {
			this.resetDefault();
			if (this.isLogin) {
				this.checkWinover();
				this.getTenant();
			} else {
				this.getTenant();
			}
		},
	},
};
</script>
